<script setup>
import DimensionOption from '@/pages/system/steps/option/dimensionOption.vue'
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'

const store = useStore()

// ref
const heightMax = ref(0)

const step = computed(() => {
  return store.getters['system/currentStep']
})
const dimension = computed(() => {
  return store.getters['system/dimension']
})
</script>
<template>
  <div v-if="dimension" class="step__body">
    <h3>{{ step.name }}</h3>
    <p>{{ step.description }}</p>
    <div class="row option">
      <DimensionOption :option="step.width" :option-dim="dimension.width" type="width"/>
      <DimensionOption :option="step.height" :option-dim="dimension.height" :height-max="heightMax" type="height"/>
    </div>
  </div>
</template>
