<template>
  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <div class="tooltip-box text-end">
          <Tooltip v-if="option.tooltip && option.tooltip.text" :tooltip="option.tooltip" />
        </div>
        <img :src="$filters.image(option.image, '350x')" class="img-fluid">
        <div class="option__name mb-2">{{ option.title }}</div>
        <select class="form-select" @change="onChange">
          <option v-for="(selectOption, index) in selectOptions" :value="selectOption.value" :selected="selectOption.selected" :key="index">{{ selectOption.label }}</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, toRefs } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  name: 'DimensionOption',
  props: {
    type: {
      require: true,
      type: String
    },
    option: {
      require: true,
      type: Object
    },
    optionDim: {
      require: true,
      type: Object
    },
    heightMax: {
      require: false,
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const store = useStore()
    const heightMax = toRefs(props).heightMax
    const optionDim = toRefs(props).optionDim
    const type = toRefs(props).type
    const selectOptions = computed(() => {
      const values = []
      const maxDim = (type.value === 'height' && heightMax.value > 0) ? heightMax.value : optionDim.value.max
      for (let i = Number.parseInt(optionDim.value.min); i <= Number.parseInt(maxDim); i += optionDim.value.step) {
        values.push({
          value: i,
          label: `${i} ${optionDim.value.unit}`,
          selected: optionDim.value.value === i
        })
      }
      return values
    })
    function onChange (e) {
      store.dispatch('system/setDimension', {
        type: type.value,
        value: e.target.value
      })
    }
    return {
      selectOptions,
      onChange
    }
  }
}
</script>
